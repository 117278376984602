import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import styled, { css } from 'styled-components';
import Skeleton from 'react-loading-skeleton';

import store, { getters } from 'redux/store';
import {
  getDialogDefault,
  getDialogAIDefault,
  getPrevPath,
  getDialogListData,
  getRecipeList,
  getRecipeFromWhichMode,
  setPrevPath,
  setDialogListData,
  setShouldGoToBottom,
} from 'redux/slices/_smartRecipe';

import HeaderBar from 'components/header-bar/header-bar-v2';
import Alert from 'components/smart-recipe/Alert';

import WebFn, { callAppFunc } from 'utils/jsbridge/index.js';
import { useAPI } from 'utils/api';
import { dismissLoading, showLoading } from 'utils/loading';
import {
  PATH_SMART_RECIPE,
  PATH_SMART_RECIPE_INFO,
  PATH_CART,
  PATH_PRODUCT,
  PATH_MY_RECIPE,
} from 'utils/constants/paths';
import {
  setRecipeDishNext,
  setRecipeDishReselect,
  setRecipeDishBack,
  setRecipeDishShare,
  setRecipeDishDelete,
  setRecipeDishSelectAll,
  setAddToCartBatch,
} from 'utils/_ga.js';

import IconCheck from 'images/icons/check.svg';
import IconShare from 'images/icons/share-btn.svg';

const Container = styled.div`
  padding-top: 60px;
`;

const Text = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #868686;
`;

const Btn = styled.div`
  background-color: #5fd2da;
  border: 1px solid #5fd2da;
  border-radius: 30px;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  padding: 8px 16px;
`;

const BtnShare = styled(Btn)`
  border: 1px solid #dddddd;
  background-color: #ffffff;
  color: #868686;
  margin-left: auto;
  position: relative;
  padding: 8px 16px 8px 38px;

  &::before {
    content: '';
    background-image: url(${IconShare});
    background-size: cover;
    width: 13px;
    height: 15px;
    position: absolute;
    top: 50%;
    left: 18px;
    transform: translateY(-50%);
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: #3b3516;
`;

const Content = styled.div`
  padding: 15px 15px 102px;
  display: flex;
  flex-direction: column;
  gap: 25px 0;
`;

const RecipeNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px 0;
`;

const RecipeName = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #3b3516;
`;

const RecipeImg = styled.img`
  width: 100%;
`;

const IngredientsWrapper = styled.div``;

const IngredientList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px 0;
`;

const StepsWrapper = styled.div``;

const StepList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 18px 0;
`;

const ListRow = styled.div`
  display: flex;
  word-break: break-word;
`;

const ListOrderNum = styled.div`
  flex: 0 0 25px;
  text-align: right;
  margin-right: 5px;
`;

const OperationWrapper = styled.div`
  display: flex;
  gap: 0 8px;
`;

const IngredientSelectWrapper = styled.div``;

const IngredientSelectList = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

const Ingredient = styled.div`
  flex: 0 1 calc((100% - 16px) / 3);
  box-sizing: border-box;
  border: 1px solid #e3e3e3;
  border-radius: 8px;
  padding: 8px 5px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
`;

const SelectBtn = styled.div`
  background-color: #eeeeee;
  border: 1px solid #dddddd;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 100;

  &::before {
    content: '';
    visibility: hidden;
    background-image: url(${IconCheck});
    background-position: center;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  ${(props) =>
    props.active &&
    css`
      background-color: #5fd2da;

      &::before {
        visibility: visible;
      }
    `}
`;

const IngredientImg = styled.img`
  width: 100%;
  margin: 0 auto 8px;
`;

const IngredientContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px 0;
`;

const IngredientPrice = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: #3b3516;

  > span {
    font-size: 12px;
    font-weight: 400;
  }
`;

const IngredientName = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #3b3516;
`;

const IngredientInCartText = styled.div`
  color: green;
  font-size: 11px;
`;

const IngredientInvalidText = styled.div`
  color: red;
  font-size: 10px;
`;

const BottomBar = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 72px;
  background-color: rgba(95, 210, 218);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
`;

const AddToCartWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0 20px;
  margin-left: auto;
`;

const SelectAllBtn = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #000000;
`;

const BtnAddToCart = styled.div`
  display: inline-block;
  padding: 8px 16px 8px 44px;
  background-color: #fff;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 400;
  color: #3b3516;
  position: relative;

  &::before {
    content: '${(props) => props.count}';
    text-align: center;
    width: 24px;
    height: 24px;
    line-height: 24px;
    border-radius: 50%;
    background-color: #f9e05f;
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
    font-size: 12px;
    font-weight: 400;
    color: #3b3516;
  }
`;

const AlertCart = styled.div``;

const AlertCartTitle = styled.div`
  text-align: left;
  font-size: 20px;
  margin-bottom: 10px;
`;

const AlertCartContentList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px 0;
  max-height: 300px;
  overflow-y: scroll;
`;

const AlertCartContent = styled.div`
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  line-height: 25px;
`;

const AddRecipeBtn = styled.div`
  display: inline-block;
  padding: 8px 16px;
  background-color: #fff;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 400;
  color: #3b3516;
  position: relative;

  ${(props) =>
    props.disabled &&
    css`
      background: #c4c4c4;
      color: #fff;
    `}
`;

const SkeletonWrapper = styled.div``;

const MODE_NORMAL = 'NORMAL';
const MODE_AI = 'AI';

const RecipeInfo = (props) => {
  const { location } = props;
  const recipeId = Number(location.pathname.split('/').pop());
  const api = useAPI();
  const pageName = '食譜詳細頁';

  const dialogDefault = useSelector((state) => getDialogDefault(state));
  const dialogAIDefault = useSelector((state) => getDialogAIDefault(state));
  const prevPath = useSelector((state) => getPrevPath(state));
  const isFromPageNormal = prevPath === PATH_SMART_RECIPE;
  const isFromMyRecipe = prevPath === PATH_MY_RECIPE;

  const currentStoreId = useSelector((state) => getters.getStoreId(state));
  const recipeList = useSelector((state) => getRecipeList(state));
  const dialogList = useSelector((state) => getDialogListData(state));
  const recipeFromWhichMode = useSelector((state) =>
    getRecipeFromWhichMode(state)
  );

  const [notification, setNotification] = useState({
    show: false,
    message: '',
  });

  const [loading, setLoading] = useState(true);
  const [recipeInfo, setRecipeInfo] = useState({});
  const [selectIngredientList, setSelectIngredients] = useState([]);
  const [cartCount, setCartCount] = useState(0);
  const [invalidProductList, setInvalidProductList] = useState([]);
  const invalidPidList = invalidProductList.map((p) => p.pid);
  const [productInCartList, setProductInCartList] = useState([]);

  const approvedPhoto = recipeInfo?.approved_photos
    ? recipeInfo?.approved_photos[0]
    : '';
  const ingredients = recipeInfo?.ingredients ?? [];
  const steps = recipeInfo?.steps ?? [];
  const recommendProducts = recipeInfo?.recommendProducts ?? [];
  const hasRecommendProducts = recommendProducts.length > 0;
  const isMyFavorite = recipeInfo.is_myfavorite;
  const myRecipeMaxCount = recipeInfo.my_recipes_limit;
  const myRecipeCount = recipeInfo.total_recipes;

  const isSelectAllProducts =
    selectIngredientList.length ===
    recommendProducts.length - invalidProductList.length;

  const {
    name,
    total_time: totalTime,
    serving_size: servingSize,
    level,
    prepare_time: prepareTime,
    cook_time: cookTime,
  } = recipeInfo;

  // 滑動到頁面最上方
  const goToTop = () => {
    setTimeout(() => {
      window.scrollTo({ top: 0 });
    });
  };

  const fetchRecipeInfo = () => {
    goToTop();
    api
      .getRecipeInfo({ storeId: currentStoreId, id: recipeId })
      .then((recipeInfo) => setRecipeInfo(recipeInfo))
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  // 不能加入購物車的商品不能勾選
  const selectAllIngredients = () => {
    const selectList = recommendProducts
      .map((p) => p.productnumber)
      .filter((num) => !invalidPidList.includes(num));
    setSelectIngredients(selectList);
  };

  const updateIngredientCountText = (validList) => {
    const newList = [...productInCartList];
    validList.forEach((num) => {
      const matchingProduct = newList.find((item) => item.productNo === num);
      if (matchingProduct) {
        matchingProduct.count += 1;
      } else {
        newList.push({
          productNo: num,
          count: 1,
        });
      }
    });
    setProductInCartList(newList);
  };

  const updateIsFavorite = () => {
    const newList = dialogList.map((dialog) => {
      if (dialog.mode === MODE_NORMAL) {
        return {
          ...dialog,
          recipeListData: {
            ...dialog.recipeListData,
            rows: dialog.recipeListData?.rows?.map((row) => {
              return row.map((r) => {
                if (r.id !== recipeInfo.recipeId) return r;
                return { ...r, is_myfavorite: true };
              });
            }),
          },
        };
      }
      if (dialog.mode === MODE_AI) {
        return {
          ...dialog,
          recipeListData: dialog.recipeListData?.map((recipe) => {
            if (recipe.id !== recipeInfo.recipeId) return recipe;
            return { ...recipe, is_myfavorite: true };
          }),
        };
      }
    });
    store.dispatch(setDialogListData(newList));
  };

  // 看其他食譜
  const handleViewOtherRecipe = () => {
    setRecipeDishNext({ pageName }); // GA
    setRecipeInfo({});
    setInvalidProductList([]);
    const nextRecipeIdx = recipeList.findIndex((r) => r.id === recipeId) + 1;
    const nextRecipeId =
      recipeList[nextRecipeIdx === recipeList?.length ? 0 : nextRecipeIdx].id;
    navigate(`${PATH_SMART_RECIPE_INFO}/${nextRecipeId}`);
  };

  // 重新選擇，如果聊天頁已經有新的一組對話框的話，則不需要新增新的對話框，防止出現兩組新的對話框
  const handleReselect = () => {
    setRecipeDishReselect({ pageName }); // GA

    let currentDialogList = dialogList;
    const lastDialog = dialogList[dialogList.length - 1];

    if (
      (lastDialog.mode === MODE_NORMAL && !lastDialog.recipeListData.rows) ||
      (lastDialog.mode === MODE_AI && !lastDialog.recipeListData)
    ) {
      currentDialogList = currentDialogList.slice(0, dialogList.length - 1);
    }

    if (recipeFromWhichMode === MODE_NORMAL) {
      currentDialogList = [...currentDialogList, dialogDefault];
    }

    if (recipeFromWhichMode === MODE_AI) {
      currentDialogList = [...currentDialogList, dialogAIDefault];
    }

    const newDialogListData = [...currentDialogList];
    store.dispatch(setDialogListData(newDialogListData));
    store.dispatch(setShouldGoToBottom(true));
    navigate(PATH_SMART_RECIPE);
  };

  // 上一頁
  const handleBack = () => {
    setRecipeDishBack({ pageName }); // GA
    const newPrevPath = prevPath.slice(0, prevPath.length - 1);
    store.dispatch(setPrevPath(newPrevPath));
    navigate(-1);
  };

  // 分享
  const handleShare = () => {
    setRecipeDishShare({ pageName }); // GA
    const { name, price, total_time, ingredients, steps } = recipeInfo;

    const ingredientText = `材料\n${ingredients
      .map((ingredient, idx) => {
        return `${idx + 1}. ${ingredient.name}（${ingredient.amount}）`;
      })
      .join('\n')}`;

    const stepText = `步驟\n${steps
      .map((step, idx) => {
        return `${idx + 1}. ${step}`;
      })
      .join('\n')}`;

    const sharedText = `${name}\n預估金額：$${price}\n估計時間：${total_time}\n\n${ingredientText}\n\n${stepText}`;
    callAppFunc('shareApp', { url: '', text: sharedText });
  };

  // 加入購物車
  const handleAddToCart = () => {
    const selectedItemList = selectIngredientList.map((id) => {
      const item = recommendProducts.find((p) => p.productnumber === id);
      return {
        ...item,
        count: 1,
        item_id: item.productnumber,
        item_name: item.item_name,
      };
    });
    setAddToCartBatch({
      itemList: selectedItemList,
      name: `食譜_${name}`,
      pageName,
    }); // GA
    if (selectIngredientList.length === 0) {
      alert('還沒有加入商品喔~');
      return;
    }
    showLoading();
    api
      .addToCartBatch({
        storeId: currentStoreId,
        productlist: selectIngredientList,
      })
      .then((res) => {
        const { invalidProductList } = res.data;
        setInvalidProductList((state) => [...state, ...invalidProductList]);
        const invalidList = invalidProductList.map((p) => {
          const productName = recommendProducts.find(
            (rp) => rp.productnumber === p.productId
          ).item_name;
          return { ...p, productName };
        });
        if (invalidList.length > 0) {
          const alertContent = (
            <AlertCart>
              <AlertCartTitle>以下商品無法加入購物車：</AlertCartTitle>
              <AlertCartContentList>
                {invalidList.map((p, idx) => {
                  return (
                    <AlertCartContent key={idx}>
                      <div>{p.productName}</div>
                      <div>原因：{p.reason}</div>
                    </AlertCartContent>
                  );
                })}
              </AlertCartContentList>
            </AlertCart>
          );
          alert(alertContent, () => {}, false);
        }

        const validList = selectIngredientList.filter((pid) => {
          return !invalidList.map((p) => p.productId).includes(pid);
        });
        if (validList.length > 0) {
          setCartCount((state) => state + validList.length);
          setNotification({ show: true, message: '商品已加入購物車' });
        }
        updateIngredientCountText(validList);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        dismissLoading();
        setSelectIngredients([]);
      });
  };

  // Header 的 "我的食譜"
  const handleGoRecipe = () => {
    store.dispatch(setPrevPath([...prevPath, PATH_SMART_RECIPE_INFO]));
    navigate(PATH_MY_RECIPE);
  };

  // Header 的 "購物車"
  const handleGoCart = () => {
    navigate(PATH_CART, { state: { page: '食譜詳細頁' } });
  };

  // 加入我的食譜
  const handleAddRecipe = () => {
    if (isMyFavorite) {
      window.Alert({ title: '已存在我的食譜', txtRight: '確認' });
      return;
    }

    if (myRecipeCount === myRecipeMaxCount) {
      window.Alert({
        title: (
          <div>
            <div>達到上限數量無法加入</div>
            <div>是否進入我的食譜</div>
          </div>
        ),
        txtLeft: '取消',
        txtRight: '確認',
        onRight: () => {
          store.dispatch(setPrevPath([...prevPath, location.pathname]));
          navigate(PATH_MY_RECIPE);
        },
      });
      return;
    }

    showLoading();
    api
      .addMyRecipe(recipeId)
      .then(() => {
        setNotification({ show: true, message: '食譜已經加入我的食譜清單中' });
        fetchRecipeInfo();
        updateIsFavorite();
      })
      .catch((err) => console.log(err))
      .finally(() => dismissLoading());
  };

  // 勾選精選食材
  const handleSelectIngredient = (idx) => {
    if (selectIngredientList.includes(idx)) {
      const newIngredients = selectIngredientList.filter((i) => i !== idx);
      setSelectIngredients(newIngredients);
    } else {
      setSelectIngredients((state) => [...state, idx]);
    }
  };

  // 全選/清除精選食材
  const handleClickSelect = () => {
    if (isSelectAllProducts) {
      setRecipeDishDelete({ pageName }); // GA
      setSelectIngredients([]);
    } else {
      setRecipeDishSelectAll({ pageName }); // GA
      selectAllIngredients();
    }
  };

  // 切換其他食譜的詳細頁時都會呼叫 info api
  useEffect(() => {
    setLoading(true);
    fetchRecipeInfo();
  }, [recipeId]);

  useEffect(() => {
    selectAllIngredients();
  }, [recipeInfo, location]);

  useEffect(() => {
    api
      .getInfo()
      .then((res) => {
        setCartCount(res.cart.count);
        setProductInCartList(res.cart.productCount);
      })
      .catch((err) => console.log(err));
  }, []);

  console.log('prevPath', prevPath);

  return (
    <>
      <Container>
        <HeaderBar
          title="智能食譜"
          onBack={handleBack}
          showMyRecipe={!isFromMyRecipe}
          myRecipeCount={myRecipeCount}
          onGoMyRecipe={handleGoRecipe}
          showCart
          cartItemCount={cartCount}
          onGoCart={handleGoCart}
        />
        <Content>
          {loading ? (
            <SkeletonWrapper>
              <Skeleton width={150} height={25} />
              <Skeleton width="100%" height={190} />
              {Array(5)
                .fill(null)
                .map((_, idx) => (
                  <Skeleton key={idx} width={150} height={20} />
                ))}
              {Array(30)
                .fill(null)
                .map((_, idx) => (
                  <Skeleton key={idx} width="100%" height={20} />
                ))}
            </SkeletonWrapper>
          ) : (
            <>
              <RecipeNameWrapper>
                {name && <RecipeName>{name}</RecipeName>}
                {approvedPhoto && <RecipeImg src={approvedPhoto} />}
                {servingSize && <Text>份量：{servingSize}</Text>}
                {level && <Text>難度：{level}</Text>}
                {totalTime && <Text>估計時間：{totalTime}</Text>}
                {prepareTime && <Text>準備時間：{prepareTime}</Text>}
                {cookTime && <Text>烹調時間：{cookTime}</Text>}
              </RecipeNameWrapper>

              <IngredientsWrapper>
                <TitleWrapper>
                  <Title>材料</Title>
                </TitleWrapper>

                <IngredientList>
                  {ingredients.map((ingredient, idx) => {
                    return (
                      <ListRow key={idx}>
                        <ListOrderNum>{idx + 1}.</ListOrderNum>
                        <div>
                          {ingredient.name}（{ingredient.amount}）
                        </div>
                      </ListRow>
                    );
                  })}
                </IngredientList>
              </IngredientsWrapper>

              <StepsWrapper>
                <TitleWrapper>
                  <Title>步驟</Title>
                </TitleWrapper>

                <StepList>
                  {steps.map((step, idx) => {
                    return (
                      <ListRow key={idx}>
                        <ListOrderNum>{idx + 1}.</ListOrderNum>
                        <div>{step}</div>
                      </ListRow>
                    );
                  })}
                </StepList>
              </StepsWrapper>

              <OperationWrapper>
                {!isFromMyRecipe && (
                  <>
                    {recipeList?.length > 1 && (
                      <Btn onClick={handleViewOtherRecipe}>看其他食譜</Btn>
                    )}
                    <Btn onClick={handleReselect}>重新選擇</Btn>
                  </>
                )}
                <BtnShare onClick={handleShare}>分享</BtnShare>
              </OperationWrapper>

              {hasRecommendProducts && (
                <IngredientSelectWrapper>
                  <TitleWrapper>
                    <Title>精選食材</Title>

                    <label className="checkbox">
                      <input
                        type="checkbox"
                        checked={isSelectAllProducts}
                        onChange={handleClickSelect}
                      />
                      <span>全選</span>
                    </label>
                  </TitleWrapper>

                  <IngredientSelectList>
                    {recommendProducts.map((product, idx) => {
                      const productNum = product.productnumber;
                      const isInvalidProduct =
                        invalidPidList.includes(productNum);
                      const invalidProductText =
                        invalidProductList.find((p) => p.pid === productNum)
                          ?.reason ?? '';
                      const productCount =
                        productInCartList.find(
                          (p) => p.productNo === productNum
                        )?.count ?? 0;

                      return (
                        <Ingredient
                          key={idx}
                          disabled={isInvalidProduct}
                          disabledText={invalidProductText}
                          onClick={() => {
                            navigate(`${PATH_PRODUCT}/${productNum}`);
                          }}
                        >
                          <SelectBtn
                            active={selectIngredientList.includes(productNum)}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleSelectIngredient(productNum);
                            }}
                          />

                          <IngredientImg src={product.product_image} />

                          <IngredientContent>
                            <IngredientPrice>
                              <span>$ </span>
                              {product.price}
                            </IngredientPrice>

                            <IngredientName>{product.item_name}</IngredientName>

                            {productCount > 0 && (
                              <IngredientInCartText>
                                數量 {productCount} 已加入購物車
                              </IngredientInCartText>
                            )}

                            {isInvalidProduct && (
                              <IngredientInvalidText>
                                {invalidProductText}
                              </IngredientInvalidText>
                            )}
                          </IngredientContent>
                        </Ingredient>
                      );
                    })}
                  </IngredientSelectList>
                </IngredientSelectWrapper>
              )}
            </>
          )}
        </Content>

        {hasRecommendProducts && (
          <BottomBar>
            <AddRecipeBtn
              disabled={myRecipeCount === myRecipeMaxCount || isMyFavorite}
              onClick={handleAddRecipe}
            >
              加入我的食譜
            </AddRecipeBtn>

            <AddToCartWrapper>
              <BtnAddToCart
                count={selectIngredientList.length}
                onClick={handleAddToCart}
              >
                加入購物車
              </BtnAddToCart>
            </AddToCartWrapper>
          </BottomBar>
        )}
      </Container>

      <Alert
        show={notification.show}
        message={notification.message}
        onClose={() => setNotification({ show: false })}
      />
    </>
  );
};

export default RecipeInfo;
